import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { bounce } from '@/app/common/style/animations';
import {
  desktopAndBelow,
  desktopLargeAndBelow,
  wideAndBelow,
  tabletAndBelow,
} from '@/app/common/style/mixins';
import { h3 } from '@/app/common/style/typography';
import { COLOR, FONTS } from '../common/style/variables';

const FeaturedCasesStyled = styled.div`
  margin-bottom: 104px;
  position: relative;

  .mini-header {
    position: relative;

    img {
      position: absolute;
      right: 0px;
      left: auto;
      animation-name: ${bounce};
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-direction: alternate;
      animation-iteration-count: infinite;
    }
  }

  .cases {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 80px;
    grid-row-gap: 88px;
    position: relative;

    > .case {
      display: flex;
      flex-direction: column;
      position: relative;

      > a {
        text-decoration: none;
        border-bottom: none;
        position: relative;
      }

      .details {
        position: relative;

        h2 {
          ${h3}
          color: ${COLOR.woodSmoke1};
          font-weight: ${FONTS.light};
          letter-spacing: 0.5px;
          margin-bottom: 0;
          position: relative;
        }
      }

      .action {
        margin-top: 24px;
        position: relative;

        .link {
          width: max-content;
          position: relative;
        }
      }

      .image-container {
        width: 100%;
        height: 620px;
        overflow: hidden;
        margin-bottom: 24px;
        position: relative;
      }
    }
  }

  ${wideAndBelow(css`
    .cases > .case {
      position: relative;

      .image-container {
        position: relative;
        height: 480px;
      }
    }
  `)}

  ${desktopLargeAndBelow(css`
    .cases > .case {
      position: relative;

      .image-container {
        height: 405px;
        position: relative;
      }
    }
  `)}

  ${desktopAndBelow(css`
    .cases {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 72px;
      position: relative;

      > .case {
        position: relative;

        .image-container {
          height: 100%;
          position: relative;
        }
      }
    }
  `)}

    ${tabletAndBelow(css`
    .cases-container {
      padding: 0;
      position: relative;

      .case {
        position: relative;

        .action {
          margin-top: 16px;
          position: relative;
        }

        .action,
        .details > *:not(.image-container) {
          position: relative;
          padding: 0 24px;
        }
      }
    }
  `)}
`;
export default FeaturedCasesStyled;
